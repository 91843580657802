import { createAction, props } from '@ngrx/store';
import { PricesGetResponse } from './../../models/price.model';

export const actionPrefix = '[prices]';

export const loadPrices = createAction(actionPrefix + ' Load Prices');

export const loadPricesSuccess = createAction(actionPrefix + ' Load Prices Success', props<{ result: PricesGetResponse }>());

export const loadPricesFailure = createAction(actionPrefix + ' Load Prices Failure', props<{ error: Error }>());
