import { Price, PricesGetResponse } from './../../models/price.model';
import { createReducer, on } from '@ngrx/store';
import * as PricesActions from './prices.actions';

export const featureKey = 'prices';

export interface State extends PricesGetResponse {
  loading: boolean;
  loaded: boolean;
  error?: Error;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  prices: [],
  template_name: ''
};

export const reducer = createReducer(
  initialState,

  on(PricesActions.loadPrices, (state, action) => ({
    ...state,
    loading: true
  })),

  on(PricesActions.loadPricesSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    prices: action.result.prices,
    template_name: action.result.template_name,
    error: undefined
  })),

  on(PricesActions.loadPricesFailure, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: action.error
  }))
);
