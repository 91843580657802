import * as fromPrices from './prices.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { Price } from './../../models/price.model';

const currencies = ['EUR', 'CHF'];

export const selectState = createFeatureSelector<fromPrices.State>(fromPrices.featureKey);

export const getPrices = createSelector(selectState, (state: fromPrices.State) => _.get(state, ['prices']));

export const getPriceTemplateName = createSelector(selectState, (state: fromPrices.State) => _.get(state, ['template_name']));

export const getCurrency = createSelector(selectState, (state: fromPrices.State) => {
  const name = _.get(state, ['template_name'], '');
  let ret = '€';

  currencies.forEach(currency => {
    if (name.toUpperCase().search('##' + currency + '##') !== -1) {
      if (currency == 'CHF') {
        ret = currency + ' ';
      } else {
        ret = currency;
      }
      ret = currency;
    }
  });

  if (ret === 'EUR') {
    ret = '€';
  }

  return ret;
});

export const getPricesError = createSelector(selectState, (state: fromPrices.State) => _.get(state, ['error']));

export const isPricesLoading = createSelector(selectState, (state: fromPrices.State) => _.get(state, ['loading']));

export const isPricesLoaded = createSelector(selectState, (state: fromPrices.State) => _.get(state, ['loaded']));
